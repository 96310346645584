/* Globals for wood despatch */
var scanningDespatch = {
		despatchId: -1,
		casksRequired: false,
		requiredQuantity: 0,
		casks: []
	};

/* globals requestTimeout, getRequestPath, renewServerSession, getScannerId, getScannerCompany, getScannerLocationCode, showAjaxLoading, hideAjaxLoading,
 searchListForItem, sortByProperty, findObjectInArray, searchArrayForObject, showAlert, createBarcodeObject, saveContainerReceiptTransaction,
 VesselBarcodeType, saveWoodDespatchTransaction, WoodDespatchTransactionCodes, willDownloadExceedAllowance, getObjectSize, playSuccessSound */

/**
 * @description Indicates if Wood Despatches are in use
 * @returns {boolean}
 */
function areDespatchesInUse() {
	"use strict";
	var value = localStorage.getVariable("settings/downloadDespatches").toUpperCase();
	return (value === "TRUE" || value === "YES");
}

/**
 * @description Gets a list of available despatches from the server
 * @param {Boolean} filterDownloaded
 * @param callback
 */
function getDespatchList(filterDownloaded, callback) {
  "use strict";
	var scanner = getScannerId(),
		company = getScannerCompany(),
		locationCode = getScannerLocationCode();

	$.ajax({
		url: getRequestPath("despatch/list?company=" + company + "&location=" + locationCode + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {
			withCredentials: true
		},
		success: function (despatchListResponseData) {
			if(filterDownloaded) {
				var alreadyDownloadedDespatches = getDespatchesFromStorage(),
					despatchesAvailable = despatchListResponseData.despatches;
				for(var i = despatchesAvailable.length-1; i>=0; i--) {
					if (searchListForItem(alreadyDownloadedDespatches,"despatchId",despatchesAvailable[i].despatchId) !== null) {
						despatchesAvailable.splice(i,1);
					}
				}
				callback(despatchesAvailable);
			} else {
				callback(despatchListResponseData.despatches);
			}
		},
		error: function (xhr) {
			if (xhr.status === 401 && canReattemptAfterLogin()) {
				renewServerSession(function (valid) {
					if (valid) {
						getDespatchList(filterDownloaded, callback);
					} else {
						callback([]);
					}
				});
			} else {
				callback([]);
			}
		}
	});
}

/**
 * @description Download a list or single despatch to the scanner
 * @param {string|Number} despatchId The despatchId or list of despatchIds to be downloaded.
 * @param callback The function to run once completed.
 */
function downloadDespatch(despatchId, callback) {
  "use strict";

	// set variables
	var scanner = getScannerId(),
		company = getScannerCompany(),
		locationCode = getScannerLocationCode();
	// ajax get single
	showAjaxLoading();

	$.ajax({
		url: getRequestPath("despatch?despatchList="+despatchId+"&company=" + company + "&location=" + locationCode + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {
			withCredentials: true
		},
		success: function (despatchData) {
			hideAjaxLoading();
			if (despatchData.despatches) {
				// Check we have at least one record
				if (despatchData.despatches[0]) {
					mergeWithDespatchesInStorage(despatchData.despatches, function(resultingDespatches, storeSuccessful) {
						// Merge completed by this point
						// Send a specific response code for when store was not successful.
						// We can tell if it previously existed by trying to get it from local storage now.
						// This only applies to one download - from selection or entry screen.
						if (!storeSuccessful) {
							if(despatchId.toString().split(",").length<=1) {
								//Single
								if(getDespatchFromStorage(despatchId)) {
									//Not Null
									callback(true, 800, resultingDespatches, despatchData.despatches);
								} else {
									callback(false, 804, resultingDespatches, despatchData.despatches);
								}
							} else {
								callback(true, 800, resultingDespatches, despatchData.despatches);
							}
						} else {
							callback(true, 200, resultingDespatches, despatchData.despatches);
						}
					});
				} else {
					callback(true, 404);
				}
			} else {
				callback (false, 404);
			}
		},
		error: function (xhr) {
			hideAjaxLoading();
			if (xhr.status === 401 && canReattemptAfterLogin()) {
				renewServerSession(function (valid) {
					if (valid) {
						downloadDespatch(despatchId, callback);
					} else {
						callback(false, 401);
					}
				});
			} else {
				callback(false, xhr.status);
			}
		}
	});
}

/**
 * @description Downloads updates to any wood despatches already on the scanner
 * @param callback Function run once downloading is completed.
 */
function updateDownloadedDespatches(callback) {
  "use strict";
	// Create list of despatches on the scanner
	var despatches = getDespatchesFromStorage(),
		despatchList = "";
	for (var i=0; i<despatches.length; i++) {
		despatchList += "," + despatches[i].despatchId;
	}
	despatchList = despatchList.substr(1);

	downloadDespatch(despatchList, function(success, responseCode, mergedDespatches, downloadedDespatches) {
		if (success) {
			// The update was successful but we may not have despatches..
			if (responseCode === 200) {
				// Response code was 200, so we received at least one
				// We will remove any despatches from the scanner that were not downloaded
				removeOldDespatchesFromScanner(mergedDespatches,downloadedDespatches, function(remainingDespatches) {
					callback(remainingDespatches, 200);
				});
			} else if (responseCode === 800) {
				// Something on the scanner, or there are several in the list trying to update.
				// Remove anything not received.
				showAlert("Cannot download despatch data. Data would exceed Quota. Old despatches will be removed.", {callback: function() {
					removeOldDespatchesFromScanner(mergedDespatches,downloadedDespatches, function(remainingDespatches) {
						callback(remainingDespatches, 800);
					});
				}});
			} else {
				// Response code would be 404, therefore no despatches are available.
				// We now clear out the downloaded despatches as they were not received in the update.
				setDespatchesInStorage([]);
				callback([], 200); //Not exactly correct but the update screen uses 200 for the message.
			}
		} else {
			if (responseCode === 404) {
				// If the server returned a 404
				setDespatchesInStorage([]);
				callback([], 200); //Not exactly correct but the update screen uses 200 for the message.
			} else if (responseCode === 804) {
				//Error but only because we could not fit on the scanner.
				showAlert("Cannot download despatch data. Data would exceed Quota. Old despatches will be removed.", {callback: function() {
					removeOldDespatchesFromScanner(mergedDespatches,downloadedDespatches, function(remainingDespatches) {
						callback(remainingDespatches, 804);
					});
				}});
			} else {
				showAlert("Could not update Despatches ("+responseCode+").", {callback: function() {
					callback(null);
				}});
			}
		}
	});
}

/**
 * @description Merges downloaded despatches with those already on the scanner.
 * @param {Object} newDespatches The despatches downloaded
 * @param callback
 */
function mergeWithDespatchesInStorage(newDespatches, callback) {
	"use strict";
	var currentStore = getDespatchesFromStorage(),
		  currentStoreSize = getObjectSize(currentStore);
	for (var i=0; i<newDespatches.length; i++) {
		var oldCopyIndex = searchListForItem(currentStore,"despatchId",newDespatches[i].despatchId);
		if (oldCopyIndex !== null) {
			currentStore[oldCopyIndex] = newDespatches[i]; // Replace old copy with the new one.
		} else {
			currentStore.push(newDespatches[i]); // Add on to the end.
		}
	}
	// resort the despatches by despatchId.
	currentStore.sort(sortByProperty("despatchId"));

	//Before we can save, we must check quota
	if (willDownloadExceedAllowance(currentStore, currentStoreSize)) {
		//TODO Alert?
		callback(getDespatchesFromStorage(), false);
	} else {
		// Save back to LS
		setDespatchesInStorage(currentStore);
		callback(currentStore, true);

	}
}

/**
 * @description Get all despatches on the scanner
 */
function getDespatchesFromStorage() {
	"use strict";
	return localStorage.getObject("data/despatches");
}

/**
 * @description get a single despatch from the scanner
 * @param {Number} despatchId The despatchId, used as the primary key
 * @returns {*}
 */
function getDespatchFromStorage(despatchId) {
	"use strict";
	var allDespatches = getDespatchesFromStorage();
	return findObjectInArray(allDespatches,"despatchId", despatchId);
}

/**
 * @description Save downloaded despatches to the scanner storage.
 * @param {object} data the array of despatches
 */
function setDespatchesInStorage(data) {
  "use strict";
	localStorage.setObject("data/despatches", data);
}

/**
 * @description Remove a single despatch from the scanner.
 * @param {Number} despatchId
 * @param callback
 */
function removeDespatchFromStorage(despatchId, callback) {
  "use strict";
	var despatches = getDespatchesFromStorage();
	var despatchToRemove = searchArrayForObject(despatches,"despatchId",despatchId);
	if (despatchToRemove >= 0) {
		despatches.splice(despatchToRemove,1);
	}
	setDespatchesInStorage(despatches);
	callback(despatches);
}

/**
 * @description Clears any despatches from the scanner if the server does not provide them in an update
 * @param {Object} scannerDespatches despatches already on the scanner
 * @param {Object} downloadedDespatches despatches downloaded from the server
 * @param callback
 */
function removeOldDespatchesFromScanner(scannerDespatches, downloadedDespatches, callback) {
	"use strict";
	var despatchesToKeep = (scannerDespatches)? scannerDespatches : getDespatchesFromStorage();
	for (var i=despatchesToKeep.length-1; i>=0;i--) {
		var downloadedIndex = searchArrayForObject(downloadedDespatches,"despatchId",despatchesToKeep[i].despatchId);
		// If we didn't find the despatch with matching despatchId in the downloaded list then remove it
		if (downloadedIndex === -1) {
			despatchesToKeep.splice(i,1);
		}
	}
	setDespatchesInStorage(despatchesToKeep);
	callback(despatchesToKeep);
}

/**
 * @description Sets the global variables for when in the wood despatch scanning screen
 * @param {Number} despatchId
 * @param callback
 */
function setScanningDespatch(despatchId, callback) {
	"use strict";
	var all = getDespatchesFromStorage(),
		despatchIndex = searchArrayForObject( all,"despatchId", despatchId),
		despatch = (despatchIndex >= 0)? all[despatchIndex] : null;
	if (despatch === null) {
		callback(false); // An error occurred
	} else {
		// Set the container details in the scanning object
		scanningDespatch.despatchId = despatchId;
		scanningDespatch.requiredQuantity = despatch.requiredQuantity;
		scanningDespatch.casksRequired = (despatch.requiredQuantity > 0);
		scanningDespatch.casks = despatch.casks || [];
		callback(true);
	}
}

/**
 * @description Handles the scan and validation of a wood despatch
 * @param {String} scannedCode The barcode scanned to be validated
 * @param {String} selectedAction The action taken, add or remove.
 * @param displayCallback
 */
function processDespatchScanEvent(scannedCode, selectedAction, displayCallback) {
	"use strict";
	//Create the barcode object (must be able to double scan IF the scan type has changed.
  var barcodeObject = createBarcodeObject(scannedCode, "scanInput", "", VesselBarcodeType.caskId);
	if (!barcodeObject.passed) return;
	var found = searchArrayForObject(scanningDespatch.casks, "caskId", barcodeObject.caskId);
	// IF action is add
	if (selectedAction === "add") {
		//check that despatch is not full (if qty not 0)
		if (scanningDespatch.casksRequired && scanningDespatch.requiredQuantity <= scanningDespatch.casks.length) {
			showAlert("The despatch has met required quantity.");
			return;
		}
		// Check cask not already scanned.
		if (found >= 0) {
			//Cask already scanned and added
			showAlert("This cask has already been added to the despatch");
			return;
		}
		// Add to casks, create transaction, and callback
		scanningDespatch.casks.push({caskId: barcodeObject.caskId});
    saveWoodDespatchTransaction(barcodeObject, scanningDespatch.despatchId, WoodDespatchTransactionCodes.add, function(success) {
			if (success) {
				displayCallback(barcodeObject.displayCode, calculateQuantityString(), "Added");
				//If completed then lets tell the user
				if (scanningDespatch.casksRequired && scanningDespatch.requiredQuantity <= scanningDespatch.casks.length) {
					showAlert("The despatch is now complete", {type: "info"});
				} else {
					playSuccessSound(true);
				}
			}
		});
	} else {
		// remove action - find the cask to remove it from casks
		if (found >= 0) {
			//found - splice it
			scanningDespatch.casks.splice(found,1);
			// Create transaction and then callback.
			saveWoodDespatchTransaction(barcodeObject, scanningDespatch.despatchId, WoodDespatchTransactionCodes.remove, function(success) {
				if (success) {
					displayCallback(barcodeObject.displayCode, calculateQuantityString(), "Removed");
					playSuccessSound(true);
				}
			});
		} else {
			// not found
			showAlert("Cask not on despatch");
		}
	}
}

/**
 * @description Calculates and returns an appropriate quantity string to display on screen.
 * @returns {string}
 */
function calculateQuantityString() {
	"use strict";
	var string = scanningDespatch.casks.length + "";
	if (scanningDespatch.casksRequired) {
		string += "/" + scanningDespatch.requiredQuantity;
	}
	return string;
}

/**
 *
 * @typedef {Object} despatchListResponseData
 * @property {Object} despatches
 *
 * @typedef {Object} despatch
 * @property {Number} despatchId
 * @property {String} location
 * @property {String} despatchType
 * @property {string} owner
 * @property {Number} requiredQuantity
 * @property {String} supplierName
 * @property {Array<despatchDetail>} casks
 *
 * @typedef {Object} despatchDetail
 * @property {String} caskId
 **/